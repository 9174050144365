<script setup lang="ts">
const auth = useAuth()

const { project, organizations } = storeToRefs(auth)
</script>

<template>
  <UPopover data-ui="UiProjectChip" mode="click" :popper="{ placement: 'top-end' }">
    <template #default="{ open }">
      <div class="border-forgd-gray-450 border rounded-lg py-2 px-3 text-xs text-forgd-primary-900 flex items-center space-x-2 w-fit">
        <div class="text-xs whitespace-nowrap">
          Current token:
        </div>
        <template v-if="project">
          <ProjectImage :src="project.image" class="h-4 w-4" />
          <div class="text-right ">
            <div class="font-semibold uppercase max-w-[150px] truncate">
              {{ project.ticker || project.name }}
            </div>
          </div>
        </template>
        <UIcon name="i-heroicons-chevron-down" class="transition w-4 h-4 text-forgd-primary-900" :class="open ? 'rotate-180' : ''" />
      </div>
    </template>
    <template #panel="{ close }">
      <div class="p-4">
        <div class="text-forgd-gray-600 text-xs">
          Signed in as
        </div>
        <div v-if="auth.me" class="text-sm font-semibold text-forgd-primary-900">
          {{ auth.me.firstName }} {{ auth.me.lastName }}
        </div>
        <div v-if="auth.me" class="text-forgd-gray-600 text-xs mb-4">
          {{ auth.me.email }}
        </div>
        <UButton to="/settings" size="xs" color="white" variant="outline" class="rounded-xl" trailing-icon="i-heroicons-cog-6-tooth">
          Account settings
        </UButton>
      </div>
      <UDivider />
      <div class="py-4 min-w-[340px]">
        <div class="text-xs text-forgd-gray-600 mb-6 px-4">
          Switch between active tokens in Forgd
        </div>
        <div class="space-y-1 px-2">
          <template v-for="org in (organizations || []).filter(org => org.memberStatus === 'active')">
            <button v-for="(p, key) in org.projects" :key="key" type="button" class="flex items-center px-2 py-1 rounded-xl hover:bg-forgd-neutral-200 justify-between w-full" @click="auth.switchProject(p) || close()">
              <div class="text-left flex items-center gap-2">
                <ProjectImage :src="p.image" class="h-7 w-7" />
                <div class="flex flex-col">
                  <div class="font-semibold uppercase max-w-[150px] truncate">
                    {{ p.ticker }}
                  </div>
                  <div class="text-xs text-forgd-gray-600 max-w-[150px] truncate">
                    {{ p.name }}
                  </div>
                  <div class="text-xs text-forgd-primary-300">
                    {{ org.ownerUserId === auth.me.id ? 'Token Admin' : 'Teammate' }}
                  </div>
                </div>
              </div>
              <div v-if="p.id === project?.id" class="bg-forgd-green-600/10 border-forgd-green-600/30 h-8 w-8 rounded-full border flex items-center justify-center">
                <UIcon name="i-heroicons-check" class="w-4 h-4 text-forgd-green-600" />
              </div>
              <UIcon v-else name="i-heroicons-chevron-right" class="w-4 h-4 text-forgd-primary-900" />
            </button>
          </template>
        </div>
      </div>
    </template>
  </UPopover>
</template>
